import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { useForm } from "src/hooks"
import withAuth from "src/hoc/withAuth"

import { Flex } from "src/styled"
import {
    Wrapper,
    ImageWrapper,
    StyledImage,
    TopLeftShadow,
    StyledContainer,
    FormContainer,
    Title,
    StepsText,
} from "src/modules/auth/components"
import SignUpForm1 from "src/modules/auth/SignUpForm1"
import SignUpForm2 from "src/modules/auth/SignUpForm2"
import { validateForm1, validateForm2 } from "src/modules/auth/validate"
import { reduceErrors } from "src/modules/auth/helper"

import { signUp } from "src/redux/user"

const initState = {
    email: "",
    password: "",
    repeatPassword: "",
    phoneNumber: "",
    nipIsActive: false,
    nip: "",
    address: "",
    name: "",
}

function SignUp() {
    const { state, handleChange, setState } = useForm(initState)
    const [step, setStep] = useState(1)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()

    const isFormActive = formNumber => formNumber === step

    function handleToggle() {
        setState(prevState => ({
            ...prevState,
            nipIsActive: !prevState.nipIsActive,
        }))
    }

    function onNextForm1(e) {
        e.preventDefault()
        const { error } = validateForm1(state)
        if (error) {
            const { details } = error
            const errorMessages = details.reduce(reduceErrors, {})
            // console.log(errorMessages)
            setErrors(errorMessages)
        } else {
            setErrors({})
            setStep(step + 1)
        }
    }

    function onNextForm2(e) {
        e.preventDefault()
        const { error } = validateForm2(state)

        if (error) {
            const { details } = error
            const errorMessages = details.reduce(reduceErrors, {})
            setErrors(errorMessages)
        } else {
            setErrors({})
            dispatch(signUp(state))
        }
    }

    return (
        <StyledContainer>
            <Wrapper>
                <TopLeftShadow />

                <FormContainer>
                    <Flex mb={20} flexDirection={"column"}>
                        <Title> Rejestracja </Title>
                        <StepsText>krok {step} z 2</StepsText>
                    </Flex>

                    <SignUpForm1
                        state={state}
                        errors={errors}
                        onChange={handleChange}
                        isActive={isFormActive(1)}
                        onNext={onNextForm1}
                    />

                    <SignUpForm2
                        state={state}
                        errors={errors}
                        onSubmit={onNextForm2}
                        onChange={handleChange}
                        isActive={isFormActive(2)}
                        handleToggle={handleToggle}
                        onPrevious={() => setStep(step - 1)}
                    />
                </FormContainer>

                <ImageWrapper>
                    <StyledImage
                        src={
                            "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcbsnews1.cbsistatic.com%2Fhub%2Fi%2F2017%2F05%2F12%2Ff9e143b5-9392-478e-8b5a-941e6986c4df%2Fistock-511991334.jpg&f=1&nofb=1"
                        }
                    />
                </ImageWrapper>
            </Wrapper>
        </StyledContainer>
    )
}

export default withAuth(SignUp, false, "/verify")
